import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

export default function Header() {
  const Site_Url = "https://namechange.co.in/";
  const BASE_URL = Site_Url + "admin/API/";

  const [languagedata, setlanguagedata] = useState([]);
  useEffect(() => {
    const Alllanguagedata = async () => {
      const response = await fetch(`${BASE_URL}Alllanguagedata.php`);
      const jsonData = await response.json();
      setlanguagedata(jsonData);
    };
    Alllanguagedata();
  }, [BASE_URL]);

  return (
    <>
      <header
        id="header"
        data-spy="affix"
        data-offset-top="60"
        data-offset-bottom="60"
      >
        <div className="container">
          <div className="row">
            <nav className="navbar">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  {" "}
                  <span className="sr-only">Toggle navigation</span>{" "}
                  <span className="icon-bar"></span>{" "}
                  <span className="icon-bar"></span>{" "}
                  <span className="icon-bar"></span>{" "}
                </button>
                <NavLink className="navbar-brand" to="/">
                  <img
                    className="headerLogo logo-dark hidden-xs"
                    src="logo1.png"
                    alt=""
                  />{" "}
                  <img
                    className="mobileHeaderlogo logo-dark hidden-lg hidden-md hidden-sm"
                    src="logo1.png"
                    alt=""
                  />
                </NavLink>{" "}
              </div>

              <div
                className="main-menu collapse navbar-collapse"
                id="bs-example-navbar-collapse-1"
              >
                <ul className="nav navbar-nav navbar-left">
                  <li>
                    {" "}
                    <NavLink className="navlink" exact="true" to="/">
                      {" "}
                      HOME
                    </NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink className="navlink" exact="true" to="/about">
                      {" "}
                      ABOUT
                    </NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink className="navlink" exact="true" to="/service">
                      {" "}
                      SERVICES
                    </NavLink>
                  </li>
                  {/* <li>
                    {" "}
                    <NavLink className="navlink" exact="true" to="/blog">
                      {" "}
                      BLOG
                    </NavLink>
                  </li> */}
                  <li className="nav-item dropdown">
                    <Link
                      className="navlink nav-link BlogDropbtn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Blog<span className="caret"></span>
                    </Link>
                    <ul className="dropdown-menu BlogDropbtnMenu">
                      {languagedata.map((value, index) => {
                        return (
                          <li key={index}>
                            <NavLink
                              className=" navlink BlogDropbtnlink dropdown-item"
                              to={`/blog/${value.language}`}
                            >
                              {" "}
                              {value.long_title}{" "}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </li>

                  <li>
                    {" "}
                    <NavLink className="navlink" exact="true" to="/contact">
                      {" "}
                      CONTACT US
                    </NavLink>
                  </li>
                </ul>

                <ul className="right-contact">
                  <li>
                    <i className="fa fa-phone" aria-hidden="true"></i> +91
                    9540005026
                  </li>
                  <li>
                    <Link to="/" className="btn btnright btn-skin ">
                      Pay Us
                    </Link>
                  </li>
                  {/* <li>
                    <a href="/" className="btn btnright btn-skin mx-2 login_btn">
                      Login
                    </a>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
