import React, { useState, useEffect } from "react";
import PageBreadcrumbs from "./PageBreadcrumbs";
import DataContent from "./DataContent";
import { Helmet } from "react-helmet";

export default function Services() {

  const Site_Url = "https://namechange.co.in/";
  const BASE_URL = Site_Url + "admin/API/";


  // Content Api
  const [contentdata, setcontentdata] = useState([]);
  useEffect(() => {
    const AllContent = async () => {
      const response = await fetch(`${BASE_URL}contentAPI.php`);
      const jsonData = await response.json();
      setcontentdata(jsonData);
    };
    AllContent();
  }, [BASE_URL]);

  //Page Meta Tags
  const [PagemetaTags, setPagemetaTags] = useState([]);
  useEffect(() => {
    const MetaTagsData = async () => {
      const response = await fetch(`${BASE_URL}pagemetaTags.php`);
      const jsonData = await response.json();
      setPagemetaTags(jsonData);
    };
    MetaTagsData();
  }, [BASE_URL]);

  return (
    <>
     <Helmet>
        <title>{PagemetaTags.ServiceMetatitle}</title>
        <meta name="keywords" content={PagemetaTags.ServiceMetaKeyword} />
        <meta name="description" content={PagemetaTags.ServiceMetaDesc} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PageBreadcrumbs pagetitle={"Services"} pageName={"services"} />

      {/* Document Services start */}
      <section id="services" className=" section_padding">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title text-dark">Document Services</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>  
          <ul className="services-list">
            <li>
              <a href="/">
                <img src="image/services/tax.png" alt="cleaning" />
                <br />
                GST & Taxes
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/refund.png" alt="electrical" />
                <br />
                Income Tax Return
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/website.png" alt="plumbing" />
                <br />
                Company Registation
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/svg.png" alt="appliances" />
                <br />
                License Registration
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/menu.png" alt="carpentry" />
                <br />
                View More
              </a>
            </li>
          </ul>
        </div>
      </section>

      {/* Home Care Services start */}
      <section id="services" className="section_padding">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title text-dark">Home Care Services</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <ul className="services-list">
            <li>
              <a href="/">
                <img src="image/services/pest.png" alt="cleaning" />
                <br />
                Pest Control Services
              </a>
            </li>
            <li>
              <a href="/">
                <img
                  src="image/services/air-conditioner (2).png"
                  alt="electrical"
                />
                <br />
                Ac Service
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/cleaning.png" alt="plumbing" />
                <br />
                Cleaning
              </a>
            </li>
            <li>
              <a href="/">
                <img
                  src="image/services/electrical-energy.png"
                  alt="appliances"
                />
                <br />
                Electrical
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/mechanic.png" alt="carpentry" />
                <br />
                Vehicle Care
              </a>
            </li>
          </ul>
        </div>
      </section>

      {/* Personal Care Services start */}
      <section id="services" className=" section_padding">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title text-dark">Personal Care Services</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <ul className="services-list">
            <li>
              <a href="/">
                <img src="image/services/yoga.png" alt="cleaning" />
                <br />
                Yoga Trainer At Home
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/healthcare.png" alt="electrical" />
                <br />
                Spa Services
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/washing.png" alt="plumbing" />
                <br />
                salon services
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/trainer.png" alt="appliances" />
                <br />
                fitness trainer at home
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/farmer.png" alt="carpentry" />
                <br />
                dietician
              </a>
            </li>
          </ul>
        </div>
      </section>

      {/* IT Services start */}
      <section id="services" className="section_padding">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title text-dark">IT Services</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <ul className="services-list">
            <li>
              <a href="/">
                <img src="image/services/web-designing.png" alt="cleaning" />
                <br />
                Website Design
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/server.png" alt="electrical" />
                <br />
                Hosting
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/email.png" alt="plumbing" />
                <br />
                Email
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/ads.png" alt="appliances" />
                <br />
                Advertisement
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/social-media.png" alt="carpentry" />
                <br />
                Social Marketing
              </a>
            </li>
          </ul>
        </div>
      </section>  

       {/* Content-3 Section Start */}
       {contentdata.title6 && (
        <DataContent
          title={contentdata.title6}
          desc={contentdata.description6}
        />
      )}

      {/* Content-3  Section End */}
      
    </>
  );
}
