import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route,Navigate } from "react-router-dom";
import Header from './Component/Header';
import Footer from './Component/Footer';
import Home from './Component/Home';
import Services from './Component/Services';
// import BecomeAproffestional from './Component/BecomeAproffestional';
// import Track from './Component/Track';
import Contact from './Component/Contact';
import Blog from './Component/Blog';
import About from './Component/About';
import BlogDetails from './Component/BlogDetails';
import ScrollToTop from './Component/ScrollToTop';
// import Header1 from './Component/Header1';



export default function App() {
  return (
    <>
      <Router>  
        <ScrollToTop/>
        <Header
        />
        <Routes>
          <Route exact="true" path="/" element={<Home />} />
          <Route exact="true" path="/about" element={<About/>} />
          <Route exact="true" path="/service" element={<Services />} />
          <Route exact="true" path="/blog/:language" element={<Blog />} />
          <Route exact="true" path="/contact" element={<Contact />} />
          <Route exact="true" path="/:lang/:slug" element={<BlogDetails />} />
          <Route exact="true" path="/:slug" element={<BlogDetails />} />
          <Route exact="true" path="/*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
