import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
  
export default function Footer() {

  const Site_Url = "https://namechange.co.in/";
  const BASE_URL = Site_Url + "admin/API/";

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  return (
    <>
      <footer>
        <div className="container-fluid footerbg">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                {" "}
                <Link to="/" className="footer-logo">
                  {" "}
                  <img
                    className="logo-dark footlogoimg"
                    src="logo1.png"
                    alt="Hire A Helper"
                  />{" "}
                </Link>
                <p>
                {webSetting.footer_content}
                </p>
                <div className="about_info">
                  <p>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                    {webSetting.address}
                  </p>
                  <p>
                    <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                    {webSetting.email}
                  </p>
                  <p>
                    <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                    {webSetting.number}
                  </p>
                </div>
              </div>
              <div className="col-md-3">  
                <h4>Services</h4>
                <ul>
                  <li>  
                    <Link to="/service">
                      <i className="fa fa-caret-right" aria-hidden="true"></i>
                      Document Translation
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <i className="fa fa-caret-right" aria-hidden="true"></i>
                      Name Change
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <i className="fa fa-caret-right" aria-hidden="true"></i>
                      AC Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <i className="fa fa-caret-right" aria-hidden="true"></i>
                      Property
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <i className="fa fa-caret-right" aria-hidden="true"></i>
                      License Registration
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <i className="fa fa-caret-right" aria-hidden="true"></i>
                      Company Registation
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <i className="fa fa-caret-right" aria-hidden="true"></i>
                      Income Tax Return
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-2">
                <h4>About Us</h4>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/blog/en">Blog</Link>
                  </li>
                  <li>
                    <Link to="/">Description</Link>
                  </li>
                  <li>
                    <Link to="/">Terms Service</Link>
                  </li>
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Product Service</Link>
                  </li>
                  <li>
                    <Link to="/">Cancellation Refund Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <h4>Singn up Newsletter</h4>
                <form action="#" method="post" className="newsletter">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control footinput"
                      placeholder="Enter Email Address"
                    />
                    <span className="input-group-btn">
                      <button className="btn btn-default" type="button">
                        <i
                          className="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </span>{" "}
                  </div>
                </form>
                <p>
                  Yourdoor Insure each any every service at yourdoorstep only.
                  Yourdoorstep have dream to facilitate every service at
                  yourdoor only. Kindly suscribe us for offers,
                </p>
              </div>
            </div>
            {/* <div className="top_awro pull-right" id="back-to-top">
              <i className="fa fa-chevron-up" aria-hidden="true"></i>{" "}
            </div> */}
          </div>
        </div>

        <div className="container-fluid bottom-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="copyright pull-left footCopywrite">
                {webSetting.copyright}
                </p>
                <ul className="footer-scoails pull-right">
                  <li>
                    <Link to={webSetting.facebook}>
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={webSetting.twitter}>
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={webSetting.linkedIn}>
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={webSetting.instagram}>
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={webSetting.youtube}>
                      <i className="fa fa-youtube" aria-hidden="true"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <ScrollToTop smooth="true" color="#f51b1b" />
    </>
  );
}
