import React from 'react';
import { NavLink } from 'react-router-dom';

export default function PageBreadcrumbs({pagetitle, pageName }) {
  return (
    <>
      <div id="page_title">
        <div className="container text-center">
          <div className="panel-heading">{pagetitle}</div>
          <ol className="breadcrumb">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li className="active">{pageName}</li>
          </ol>
        </div>
      </div>
    </>
  );
}
