import React, { useState, useEffect } from "react";
import PageBreadcrumbs from "./PageBreadcrumbs";
import { Helmet } from "react-helmet";

export default function Contact() {
  const Site_Url = "https://namechange.co.in/";
  const BASE_URL = Site_Url + "admin/API/";
  
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  //Page Meta Tags
  const [PagemetaTags, setPagemetaTags] = useState([]);
  useEffect(() => {
    const MetaTagsData = async () => {
      const response = await fetch(`${BASE_URL}pagemetaTags.php`);
      const jsonData = await response.json();
      setPagemetaTags(jsonData);
    };
    MetaTagsData();
  }, [BASE_URL]);
  return (
    <>
      <Helmet>
        <title>{PagemetaTags.ContactMetatitle}</title>
        <meta name="keywords" content={PagemetaTags.ContactMetaKeyword} />
        <meta name="description" content={PagemetaTags.ContactMetaDesc} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PageBreadcrumbs pagetitle={"Contact Us"} pageName={"contact"} />

      {/* Contact Form Start */}
      <section id="Contact_form">
        {/* <div className="contacts_mape">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1229.5864897864183!2d75.76904979762698!3d26.886852269789564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1499667244188"
            width="1600"
            height="490"
            allowFullScreen
            title="Address"
          ></iframe>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-8 col-xs-12">
              <div className="booking_form">
                <div className="container-fluid">
                  <div className="row">
                    <form>
                      <h2>Contact Form</h2>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <input
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Full Name"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <input
                          className="form-control"
                          id="Email"
                          name="Email"
                          placeholder="Email"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <input
                          className="form-control"
                          id="Subject"
                          name="Subject"
                          placeholder="Subject*"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <input
                          className="form-control"
                          id="Phone_number"
                          name="Phone_number"
                          placeholder="Phone Number"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <div className="input-group">
                          <textarea
                            className="form-control"
                            rows="6"
                            placeholder="Message"
                            name="message"
                          ></textarea>
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <button
                          className="btn btn-primary btn-skin"
                          name="submit"
                          type="submit"
                        >
                          {" "}
                          Send Message
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about_info">
                <h2>Contact info</h2>
                <p>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                  {webSetting.address}
                </p>
                <p>
                  <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                  {webSetting.email}
                </p>
                <p>
                  <i className="fa fa-phone" aria-hidden="true"></i> {webSetting.number}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Form End */}
    </>
  );
}
