import React, { useState, useEffect } from "react";

import PageBreadcrumbs from "./PageBreadcrumbs";
import BlogPageBox from "./BlogPageBox";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Blog() {
  let { language } = useParams();
  const Site_Url = "https://namechange.co.in/";
  const BASE_URL = Site_Url + "admin/API/";


  const [Blogs, setBlogsdata] = useState([]);
  useEffect(() => {
    const AllBlogs = async () => {
      const response = await fetch(`${BASE_URL}blogAPI.php`);
      const jsonData = await response.json();
      const resultfilter = jsonData.filter((langcat) => {
        return langcat.lang === language;
      });
      setBlogsdata(resultfilter);
    };
    AllBlogs();
  }, [language, BASE_URL]);

  const [catmetaTags, setcatmetaTags] = useState([]);
  useEffect(() => {
    const catMetaTagsData = async () => {
      const response = await fetch(
        `${BASE_URL}alllanguages.php?langcat=${language}`
      );
      const jsonData = await response.json();
      // console.log(jsonData);
      setcatmetaTags(jsonData);
    };
    catMetaTagsData();
  });

  return (
    <>

<Helmet>
        <title>{catmetaTags.metatitle}</title>
        <meta name="keywords" content={catmetaTags.metalink} />
        <meta name="description" content={catmetaTags.metadescription} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PageBreadcrumbs pagetitle={"Blogs"} pageName={"blog"} />

      {/* Blog section Start */}
      <section className="blog_section section_padding section_padding_bottom">
        <div className="container">
          <div className="row">
            {Blogs.map((value, index) => (
              <BlogPageBox
              key={index}
              slug={value.slug}
              lang={value.lang}
              image={value.sort_img}
              image_title={value.image_title}
              image_alt={value.image_alt}
              date={value.date}
              title={value.title}
              tagline={value.tagline}
            />
            ))}
          </div>
        </div>
      </section>
      {/* Blog section End */}
    </>
  );
}
