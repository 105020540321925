import React, { useState, useEffect } from "react";

// import OwlCarousel from "react-owl-carousel";
import BlogHomebox from "./BlogHomebox";
import DataContent from "./DataContent";
import MetaData from "./MetaData";

export default function Home() {
  const Site_Url = "https://namechange.co.in/";
  const BASE_URL = Site_Url + "admin/API/";

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  // Blog API
  const [Blogs, setBlogsdata] = useState([]);
  useEffect(() => {
    const AllBlogs = async () => {
      const response = await fetch(`${BASE_URL}homeblogAPI.php`);
      const jsonData = await response.json();
      setBlogsdata(jsonData);
    };
    AllBlogs();
  }, [BASE_URL]);

  // Content Api
  const [contentdata, setcontentdata] = useState([]);
  useEffect(() => {
    const AllContent = async () => {
      const response = await fetch(`${BASE_URL}contentAPI.php`);
      const jsonData = await response.json();
      setcontentdata(jsonData);
    };
    AllContent();
  }, [BASE_URL]);

  //Testimonial API
  const [clientdata, setclientdata] = useState([]);
  useEffect(() => {
    const Allclient = async () => {
      const response = await fetch(`${BASE_URL}clientAPI.php`);
      const jsonData = await response.json();
      setclientdata(jsonData);
    };
    Allclient();
  }, [BASE_URL]);

  // const clientOwl = {
  //   loop: true,
  //   margin: 30,
  //   responsiveClass: true,
  //   nav: false,
  //   dots: false,
  //   autoplay: true,
  //   navText: ["&lt;", ">"],
  //   smartSpeed: 1000,
  //   center: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     400: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 1,
  //     },
  //     700: {
  //       items: 2,
  //     },
  //     1000: {
  //       items: 5,
  //     },
  //   },
  // };
  return (
    <>
      <MetaData />

      {/* Home banner Start */}
      <section id="banner" className="home-one section_padding">
        <div className="container text-center parallax-section">
          <div className="row text-center">
            <div className="col-md-12">
              <h1 className="panel-heading">Ready to Live Smarter?</h1>
              <p className="caption">
                Get instant access to reliable and affordable services
              </p>
              <form className="form-inline book-now-home">
                <div className="form-group">
                  <div className="input-group">
                    <div className="dropdown category-dropdown text-left">
                      {" "}
                      <a data-toggle="dropdown" href="/">
                        <i className="fa fa-list" aria-hidden="true"></i>{" "}
                        <span className="change-text">Select Service</span>{" "}
                        <i
                          className="fa fa-caret-down pull-right"
                          aria-hidden="true"
                        ></i>{" "}
                      </a>
                      <ul className="dropdown-menu category-change">
                        <li>
                          <a href="/">Fashion&amp;Beauty</a>
                        </li>
                        <li>
                          <a href="/">Cars &amp; Vehicles</a>
                        </li>
                        <li>
                          <a href="/">Electronics&amp;Gedgets</a>
                        </li>
                        <li>
                          <a href="/">Real Estate</a>
                        </li>
                        <li>
                          <a href="/">Sports &amp; Games</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group location-code">
                    <input
                      type="text"
                      className="form-control zipcode"
                      id="zipcode"
                      placeholder="Pincode"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary booknow btn-skin"
                >
                  Book now
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* Home banner Start */}

      {/* How Work Section Start */}
      <section className="howWork_section section_padding section_padding_bottom ">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title text-dark">How It Works</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="steps">
                <ul className="list-unstyled">
                  <div className="row">
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block stepss askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-hand-o-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 1</span>
                              <h5>Select the Service</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-share-square-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 2</span>
                              <h5>Send Inquiry</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block stepss askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-question"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 3</span>
                              <h5>Ask Question</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block stepss askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 4</span>
                              <h5>Send Document</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-money"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 5</span>
                              <h5>Pay Online</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li className="last_staps6">
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-hourglass-start"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 6</span>
                              <h5>Work Start</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="img_right_box">
                <img
                  src={webSetting.howwork_img}
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* How Work Section End */}
      {/* Content-1 Section Start */}

      {contentdata.title1 && (
        <DataContent
          title={contentdata.title1}
          desc={contentdata.description1}
        />
      )}

      {/* Content-1 Section End */}
      {/* Document Services start */}
      <section id="services" className="section_bg section_padding">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border devider_border_color" />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title textLight">Document Services</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border devider_border_color" />
            </div>
          </div>
          <ul className="services-list">
            <li>
              <a href="/">
                <img src="image/services/tax.png" alt="cleaning" />
                <br />
                GST & Taxes
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/refund.png" alt="electrical" />
                <br />
                Income Tax Return
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/website.png" alt="plumbing" />
                <br />
                Company Registation
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/svg.png" alt="appliances" />
                <br />
                License Registration
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/menu.png" alt="carpentry" />
                <br />
                View More
              </a>
            </li>
          </ul>
        </div>
      </section>

      {contentdata.title2 && (
        <DataContent
          title={contentdata.title2}
          desc={contentdata.description2}
        />
      )}

      {/* Home Care Services start */}
      <section id="services" className="section_padding">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title text-dark">Home Care Services</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <ul className="services-list">
            <li>
              <a href="/">
                <img src="image/services/pest.png" alt="cleaning" />
                <br />
                Pest Control Services
              </a>
            </li>
            <li>
              <a href="/">
                <img
                  src="image/services/air-conditioner (2).png"
                  alt="electrical"
                />
                <br />
                Ac Service
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/cleaning.png" alt="plumbing" />
                <br />
                Cleaning
              </a>
            </li>
            <li>
              <a href="/">
                <img
                  src="image/services/electrical-energy.png"
                  alt="appliances"
                />
                <br />
                Electrical
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/mechanic.png" alt="carpentry" />
                <br />
                Vehicle Care
              </a>
            </li>
          </ul>
        </div>
      </section>

      {/* Content-3 Section Start */}
      {contentdata.title3 && (
        <DataContent
          title={contentdata.title3}
          desc={contentdata.description3}
        />
      )}

      {/* Content-3  Section End */}

      {/* Personal Care Services start */}
      <section id="services" className="section_bg section_padding">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border devider_border_color" />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title textLight">Personal Care Services</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border devider_border_color" />
            </div>
          </div>
          <ul className="services-list">
            <li>
              <a href="/">
                <img src="image/services/yoga.png" alt="cleaning" />
                <br />
                Yoga Trainer At Home
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/healthcare.png" alt="electrical" />
                <br />
                Spa Services
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/washing.png" alt="plumbing" />
                <br />
                salon services
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/trainer.png" alt="appliances" />
                <br />
                fitness trainer at home
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/farmer.png" alt="carpentry" />
                <br />
                dietician
              </a>
            </li>
          </ul>
        </div>
      </section>

      {/* Content-4 Section Start */}

      {contentdata.title4 && (
        <DataContent
          title={contentdata.title4}
          desc={contentdata.description4}
        />
      )}

      {/* Content-4 Section End */}

      {/* IT Services start */}
      <section id="services" className="section_padding">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title text-dark">IT Services</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <ul className="services-list">
            <li>
              <a href="/">
                <img src="image/services/web-designing.png" alt="cleaning" />
                <br />
                Website Design
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/server.png" alt="electrical" />
                <br />
                Hosting
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/email.png" alt="plumbing" />
                <br />
                Email
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/ads.png" alt="appliances" />
                <br />
                Advertisement
              </a>
            </li>
            <li>
              <a href="/">
                <img src="image/services/social-media.png" alt="carpentry" />
                <br />
                Social Marketing
              </a>
            </li>
          </ul>
        </div>
      </section>

      {/*trust-security start */}
      {/* <section id="trust-security" className="section_padding">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border devider_border_color" />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title textLight">Your Trust and Security</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border devider_border_color" />
            </div>
          </div>
          <div className="row text-left">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/time.png" alt="SAVES" />
                </div>
                <div className="box_content">
                  <h4>SAVES YOU TIME</h4>
                  <p>
                    We helps you live smarter, giving you time to focus on
                    what's most important.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/Safety.png" alt="Safety" />
                </div>
                <div className="box_content">
                  <h4>For Your Safety</h4>
                  <p>
                    All of our Helpers undergo rigorous identity checks and
                    personal interviews. Your safety is even our concern too.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/best.png" alt="Best" />
                </div>
                <div className="box_content">
                  <h4>Best-Rated Professionals</h4>
                  <p>
                    Our experienced taskers perform their tasks with dedication
                    and perfection. We appreciate your reviews about the
                    service.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/Equipped.png" alt="Equipped" />
                </div>
                <div className="box_content">
                  <h4>We Are Well Equipped</h4>
                  <p>
                    Let us know if you have any specific equirement, otherwise
                    our guys carry their own supplies.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/touch.png" alt="Always" />
                </div>
                <div className="box_content">
                  <h4>Always In Touch</h4>
                  <p>
                    Book your service online on one tap, keep a track of your
                    service status and also keep in touch with your Helper.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/cash.png" alt="cash" />
                </div>
                <div className="box_content">
                  <h4>Cash-Free Facility</h4>
                  <p>
                    Pay through secure online mode only after your job is done.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="numbers" className="section_padding">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter_box text-center">
                <div className="counter_icon">
                  <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>
                </div>
                <div className="counter_number counter">
                  <span className="stat-count">100</span>%
                </div>
                <h4 className="counter_name">Quality</h4>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter_box text-center">
                <div className="counter_icon">
                  <i className="fa fa-user" aria-hidden="true"></i>
                </div>
                <div className="counter_number counter">
                  <span className="stat-count">2500</span>+
                </div>
                <h4 className="counter_name">People Working</h4>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter_box text-center">
                <div className="counter_icon">
                  <i className="fa fa-calendar-o" aria-hidden="true"></i>
                </div>
                <div className="counter_number counter">
                  <span className="stat-count">8</span> Years
                </div>
                <h4 className="counter_name">Years Experience</h4>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter_box text-center">
                <div className="counter_icon">
                  <i className="fa fa-smile-o" aria-hidden="true"></i>
                </div>
                <div className="counter_number counter">
                  <span className="stat-count">900</span>+
                </div>
                <h4 className="counter_name">Happy Smiles</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="section_padding"> 
        <div className="container text-center features-section">
          <div className="row text-left">
            <div className="col-md-6 col-sm-6 col-xs-12 text-center">
              {" "}
              <img
                src="image/Home/qualit_work.png"
                alt="Meet the Hire Pros"
              />{" "}
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <h2>Meet the Yourdoorstep</h2>
              <div className="icon_box_one">
                {" "}
                <i
                  className="fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                ></i>
                <div className="box_content">
                  <h4>Yourdoor Step have Team of Expert of there field</h4>
                  <p>
                    Yourdoorstep tranined manpower to serve you at your doorstep
                    since 2011
                  </p>
                </div>
              </div>
              <div className="icon_box_one">
                {" "}
                <i
                  className="fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                ></i>
                <div className="box_content">
                  <h4>No payment, If not Satisfied at YourDoorstep</h4>
                  <p>
                    No payment, If not Satisfied with service
                    <br /> Yourdoorstep Not charge single peny to client if he
                    not staisfed with service as concultancy.
                  </p>
                </div>
              </div>
              <div className="icon_box_one">
                {" "}
                <i
                  className="fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                ></i>
                <div className="box_content">
                  <h4>On time and Quality work promise</h4>
                  <p>
                    Yourdoorstep promise all our client to serve quality and on
                    time work .
                  </p>
                </div>
              </div>
              <div className="icon_box_one">
                {" "}
                <i
                  className="fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                ></i>
                <div className="box_content">
                  <h4>Just book service with Your Doorstep and seat back</h4>
                  <p>
                    Once you book service with Yourdoorstep then you need not to
                    worry at all. Expets will do you work
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* testimonails start */}
      <section id="testimonails" className="">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title text-dark">Testimonails</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                id="carousel-example-generic"
                className="carousel slide"
                data-ride="carousel "
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#carousel-example-generic"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-target="#carousel-example-generic"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carousel-example-generic"
                    data-slide-to="2"
                  ></li>
                </ol>
                <div className="carousel-inner text-center">
                  {clientdata.map((value, index) => (
                    <div key={index} className={`item ${index === 0?"active":""}`}>
                      <div className="avatar clientimgBox">
                        <img
                          className="img-circle clientImg"
                          src={value.image}
                          alt="Client 1"
                        />
                      </div>
                      <h3>{value.name}</h3> 
                      <strong>{value.designation}</strong>
                      <p>
                      {value.description}
                      </p>
                    </div>
                  ))}

                  {/* <div className="item">
                    <div className="avatar">
                      <img
                        className="img-circle"
                        src="image/Testimonial/clinte2.jpg"
                        alt="Client 2"
                      />
                    </div>
                    <h3>SOMSA PHARMA</h3>
                    <strong>Lorem Ipsum</strong>
                    <p>
                      Very good service... Also the work done very fast... I
                      really appreciate... Thank you so much.... Team
                    </p>
                  </div>
                  <div className="item">
                    <div className="avatar">
                      <img
                        className="img-circle"
                        src="image/Testimonial/clinte3.jpg"
                        alt="Client 3"
                      />
                    </div>
                    <h3>SOMSA PHARMA</h3>
                    <strong>Lorem Ipsum</strong>
                    <p>
                      it was woow experience ....seamless service @ minimal
                      cost. the team is great and fast. highly recommended
                    </p>
                  </div> */}
                </div>
                <a
                  className="left carousel-control"
                  href="#carousel-example-generic"
                  data-slide="prev"
                >
                  {" "}
                  <span className="fa fa-angle-left"></span>{" "}
                </a>{" "}
                <a
                  className="right carousel-control"
                  href="#carousel-example-generic"
                  data-slide="next"
                >
                  {" "}
                  <span className="fa fa-angle-right"></span>{" "}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* testimonails End */}

      {/* Content-5 Section Start */}

      {contentdata.title5 && (
        <DataContent
          title={contentdata.title5}
          desc={contentdata.description5}
        />
      )}

      {/* Content-5 Section End */}

      {/* <section id="downlod_app" className="section_padding">
        <div className="container text-center">
          <div className="row text-left">
            <div className="col-md-5 col-sm-12 col-xs-12 responsive-look">
              {" "}
              <img src="image/Home/mobile.png" alt="DOWNLOAD APP NOW" />{" "}
            </div>
            <div className="col-md-7 col-sm-12 col-xs-12">
              <div className="downlod_text text-center">
                <h2>
                  <strong>DOWNLOAD</strong> APP NOW
                </h2>
                <p>
                  Slect your device platform and get
                  <br />
                  download start
                </p>
                <button type="button" className="btn btn-primary btn-outline">
                  <i className="fa fa-apple" aria-hidden="true"></i> APPLE USER
                </button>
                <button type="button" className="btn btn-primary btn-outline">
                  <i className="fa fa-android" aria-hidden="true"></i> ANDROAID
                  USER
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="trusted" className="section_padding">
        <div className="container text-center">
          <div className="row text-left">
            <div className="col-md-2 col-sm-3 col-xs-12">
              <h3 className="panel-heading">TRUSTED BY</h3>
            </div>
            <div className="col-md-10 col-sm-12 col-xs-12">
              <OwlCarousel {...clientOwl}>
                <div className="item">
                  <a href="/" className="trustedClient">
                    <img
                      src="image/Ourclient/Trusted_logo1.png"
                      alt="Logo 1"
                      className="trustedClientimg"
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="/" className="trustedClient">
                    <img
                      src="image/Ourclient/Trusted_logo2.png"
                      alt="Logo 1"
                      className="trustedClientimg"
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="/" className="trustedClient">
                    <img
                      src="image/Ourclient/Trusted_logo3.png"
                      alt="Logo 1"
                      className="trustedClientimg"
                    />
                  </a>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      <section id="call-to-action" className="section_padding ">
        <div className="container free_consultation">
          <div className="row">
            <div className="col-md-8 col-sm-8 col-xs-12 text-left">
              <h2>Wanted a Free Consultation?</h2>
              <p>we are always ready to welcome you!</p>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 m-text-center text-right">
              {" "}
              <a href="/" className="btn btn-primary btn-outline">
                Schedule Cleaning{" "}
              </a>{" "}
            </div>
          </div>
        </div>
      </section> */}

      {/* Blog section Start */}
      <section className="blog_section">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <hr className="before_border" />
            </div>
            <div className="col-md-2">
              <div className="section_title">
                <h2 className="title text-dark">Blogs</h2>
              </div>
            </div>
            <div className="col-md-5">
              <hr className="before_border" />
            </div>
          </div>
          <div className="row">
            {Blogs.map((value, index) => (
              <BlogHomebox
                key={index}
                slug={value.slug}
                lang={value.lang}
                image={value.sort_img}
                image_title={value.image_title}
                image_alt={value.image_alt}
                date={value.date}
                title={value.title}
                tagline={value.tagline}
              />
            ))}
          </div>
        </div>
      </section>
      {/* Blog section End */}
    </>
  );
}
