import React, { useState, useEffect } from "react";
import PageBreadcrumbs from "./PageBreadcrumbs";
import { Helmet } from "react-helmet";

export default function About() {
  const Site_Url = "https://namechange.co.in/";
  const BASE_URL = Site_Url + "admin/API/";

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  //Page Meta Tags
  const [PagemetaTags, setPagemetaTags] = useState([]);
  useEffect(() => {
    const MetaTagsData = async () => {
      const response = await fetch(`${BASE_URL}pagemetaTags.php`);
      const jsonData = await response.json();
      setPagemetaTags(jsonData);
    };
    MetaTagsData();
  }, [BASE_URL]);

  return (
    <>
      <Helmet>
        <title>{PagemetaTags.AboutMetatitle}</title>
        <meta name="keywords" content={PagemetaTags.AboutMetaKeyword} />
        <meta name="description" content={PagemetaTags.AboutMetaDesc} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PageBreadcrumbs pagetitle={"About Us"} pageName={"about"} />
      {/* page content Section Satrt */}
      <div id="about" className="section_padding section_padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 about_l aboutimageBox">
              {" "}
              <img src={webSetting.about_img} alt="desk" />{" "}
            </div>
            <div className="col-md-6 col-sm-6 about_text_box" >
              <h1 className="about_title">{webSetting.about_title}</h1>
              <p
                className="about_desc"
                dangerouslySetInnerHTML={{
                  __html: webSetting.about_desc,
                }}
              ></p>
              {/* <button
                type="button"
                className="btn btn-default btn-lg skin-border"
              >
                Large button
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* page content Section End */}

      {/* Start team area */}
      {/* <section className="team-area section_padding">
        <div className="container">
          <h1 className="panel-heading text-center">Our Team</h1>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-team-member text-center">
                <div className="img-holder">
                  {" "}
                  <img
                    src="images/about/Team1.png"
                    className="img-circle"
                    alt="img"
                  />{" "}
                </div>
                <div className="text-holder text-center">
                  <h3>ADAM FINLAY</h3>
                  <p>Expert Cleaner</p>
                  <ul className="social-links">
                    <li>
                      <a href="/">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-instagram" aria-hidden="true"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-team-member text-center">
                <div className="img-holder">
                  {" "}
                  <img
                    src="images/about/Team2.png"
                    className="img-circle"
                    alt="img"
                  />{" "}
                </div>
                <div className="text-holder text-center">
                  <h3>ADAM FINLAY</h3>
                  <p>Expert Cleaner</p>
                  <ul className="social-links">
                    <li>
                      <a href="/" rel="noopener noreferrer">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-instagram" aria-hidden="true"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-team-member text-center">
                <div className="img-holder">
                  {" "}
                  <img
                    src="images/about/Team3.png"
                    className="img-circle"
                    alt="team"
                  />{" "}
                </div>
                <div className="text-holder text-center">
                  <h3>ADAM FINLAY</h3>
                  <p>Expert Cleaner</p>
                  <ul className="social-links">
                    <li>
                      <a href="/">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-instagram" aria-hidden="true"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-team-member text-center">
                <div className="img-holder">
                  {" "}
                  <img
                    src="images/about/Team4.png"
                    className="img-circle"
                    alt="as"
                  />{" "}
                </div>
                <div className="text-holder text-center">
                  <h3>ADAM FINLAY</h3>
                  <p>Expert Cleaner</p>
                  <ul className="social-links">
                    <li>
                      <a href="/">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-instagram" aria-hidden="true"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* End Start team area */}

      {/* Service Provider Satrt */}
      {/* <section id="service_provider " className="section_padding">
        <div className="container text-center">
          <h1 className="panel-heading">
            Worldwide largest home service provider
          </h1>
          <div className="row">
            <div className="col-md-12">
              <div className="counter_box">
                <div className="counter_number_right">
                  <div className="counter_number counter">
                    <span className="stat-count">20000</span>+
                  </div>
                  <h4 className="counter_name">HAPPY CUSTOMERS</h4>
                </div>
              </div>

              <div className="counter_box">
                <div className="counter_number_right">
                  <div className="counter_number counter">
                    <span className="stat-count">10000</span>+
                  </div>
                  <h4 className="counter_name">SERVICE PROVIDERS</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Service Provider Satrt End */}

      {/* Testimonails Section Satrt */}
      {/* <section id="testimonails" className="section_padding">
        <div className="container text-center">
          <h1 className="panel-heading">Testimonails</h1>
          <div className="row">
            <div className="col-md-12">
              <div
                id="carousel-example-generic"
                className="carousel slide"
                data-ride="carousel "
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#carousel-example-generic"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-target="#carousel-example-generic"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carousel-example-generic"
                    data-slide-to="2"
                  ></li>
                </ol>
                <div className="carousel-inner text-center">
                  <div className="item active">
                    <div className="avatar">
                      <img
                        className="img-circle"
                        src="images/clinte1.png"
                        alt="clinte1"
                      />
                    </div>
                    <h3>Kevin Austin</h3>
                    <strong>Lorem Ipsum</strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, a
                    </p>
                  </div>
                  <div className="item">
                    <div className="avatar">
                      <img
                        className="img-circle"
                        src="images/testimonails2.png"
                        alt="clinte2"
                      />
                    </div>
                    <h3>Kevin Austin</h3>
                    <strong>Lorem Ipsum</strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, a
                    </p>
                  </div>
                  <div className="item">
                    <div className="avatar">
                      <img
                        className="img-circle"
                        src="images/testimonails3.png"
                        alt="clinte3"
                      />
                    </div>
                    <h3>Kevin Austin</h3>
                    <strong>Lorem Ipsum</strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, a
                    </p>
                  </div>
                </div>
                <a
                  className="left carousel-control"
                  href="#carousel-example-generic"
                  data-slide="prev"
                >
                  {" "}
                  <span className="fa fa-angle-left"></span>{" "}
                </a>{" "}
                <a
                  className="right carousel-control"
                  href="#carousel-example-generic"
                  data-slide="next"
                >
                  {" "}
                  <span className="fa fa-angle-right"></span>{" "}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Testimonails Section End */}
    </>
  );
}
